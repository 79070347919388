var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "my-3" },
        _vm._l(_vm.coupons, function(coupon, index) {
          return _c(
            "v-col",
            { key: "coupon-" + index, attrs: { cols: "12", sm: "6", lg: "4" } },
            [_c("UsedCouponCard", { attrs: { coupon: coupon } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }