<template>
  <v-container>
    <v-row class="my-3">
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(coupon, index) in coupons"
        :key="'coupon-' + index"
      >
        <UsedCouponCard :coupon="coupon" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CouponsService from "@/service/couponsService";
import UsedCouponCard from "@/components/coupon/UsedCouponCard.vue";
import get from "lodash/get";
export default {
  name: "DiscountHistory",
  components: { UsedCouponCard },
  data() {
    return {
      coupons: []
    };
  },
  methods: {
    formatDate (dateString){
    // Split the input date string (assuming "YYYY-DD-MM" format)
    const [year, month, day] = dateString.split('-');

    // Reformat the date to "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
    },
    async reload() {
      
      await CouponsService.getRedeemedDiscounts().then(data => {
        if (data.values) {
          this.coupons.push(
            ...data.values.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.formatDate(item.dateRedemption.substring(0, 10)),
              amount: item.product.name,
              img: item.product.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      
      await CouponsService.getRedeemedTotalCoupons("true").then(data => {
        data.giftCertificates.forEach(element => {
          if (element && element?.metaData?.voucher_info?.redemption_dates !== undefined) {
            let redemptionDates = element.metaData.voucher_info.redemption_dates;
            
            if (redemptionDates && redemptionDates !== '') {
              // Modify the redemptionDates as needed
              redemptionDates = redemptionDates.slice(1, -1).split(", ");

              element.metaData.voucher_info.redemption_dates = redemptionDates;
            }
          }
        });

          this.coupons.push(
            ...data.giftCertificates.map(item => ({
              name: item.name,
              date: null,
              amount: item.userGiftCertificates[0].amount,
              amountType: this.discountType(
                get(item, "giftAdjustmentType.descr")
              ),
              img: item.categoryProposal.img,
              type: "discount",
              redemptionDates:item.metaData?.voucher_info?.redemption_dates
            }))
          );
      });
      
      await CouponsService.getRedeemedDiscountsByType("cashback").then(data => {
        if (data.values && data.values.length > 0) {
          this.coupons.push(
            ...data.values?.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.formatDate(item.startDate.substring(0, 10)),
              amount: item.name,
              img: item.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedDiscountsByType("welcome").then(data => {
        if (data.values && data.values.length > 0) {
          this.coupons.push(
            ...data.values?.map(item => ({
              name: this.$t("coupons.discounts.discountCoupon"),
              date: this.$dayjs(item.startRetreat, "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              ), //??
              amount: item.product.name,
              img: item.mediaURL,
              type: "discount"
            }))
          );
        }
      });
      await CouponsService.getRedeemedDonations().then(data => {
        if (data.values) {
          this.coupons.push(
            ...data.values.map(item => ({
              name: this.$t("coupons.discounts.donatePoints"),
              description: item.product.name,
              date: this.formatDate(item.dateRedemption.substring(0, 10)),
              amount: "",
              img: item.product.mediaURL,
              type: "donation"
            }))
          );
        }
      });
      
    },
    discountType(type) {
      //let type = get(this.coupon, "giftAdjustmentType.descr");
      switch (type) {
        case "PercentOff":
          return "%";
        case "FixedAmounOff":
          return "€";
        default:
          return "";
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
